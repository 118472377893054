@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}
body {
	@apply antialiased;
}
.table {
	border-spacing: 0 15px;
}

i {
	font-size: 1rem !important;
}

.table tr {
	border-radius: 20px;
}

tr td:nth-child(n + 9),
tr th:nth-child(n + 9) {
	border-radius: 0 0.625rem 0.625rem 0;
}

tr td:nth-child(1),
tr th:nth-child(1) {
	border-radius: 0.625rem 0 0 0.625rem;
}
